import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthGuard} from './auth/auth.guard';
import {TwitterDisputesComponent} from './disputes/twitter-disputes/twitter-disputes.component';
import {ClientChatComponent} from './client-chat/client-chat.component';
import {ChatComponent} from './chat/chat.component';


const routes: Routes = [
  {
    path: 'chat/clients',
    component: ClientChatComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chat/:chatType/:clientId',
    component: ChatComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'disputes/twitter',
    component: TwitterDisputesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
