import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {EmbeddedTweet} from './models/embedded-tweet.model';
import {TweetService} from './tweet.service';

@Component({
  selector: 'app-tweet',
  templateUrl: './tweet.component.html',
  styleUrls: ['./tweet.component.scss']
})
export class TweetComponent implements OnInit {

  @Input()
  public tweetId: string;

  @Input()
  public twitterUsername: string;

  embed: EmbeddedTweet = new EmbeddedTweet();

  constructor(private renderer: Renderer2,
              private tweetService: TweetService,
              private el: ElementRef) {
  }

  ngOnInit(): void {
    this.tweetService.getEmbedded(this.twitterUsername, this.tweetId).then(et => {
      this.embed = et;
    }).then(p =>
      {
        const scriptEl = document.createElement('script');
        scriptEl.src = 'https://platform.twitter.com/widgets.js';
        this.renderer.appendChild(this.el.nativeElement, scriptEl);
      }
    );
  }
}
