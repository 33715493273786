// the values below are changed in the build pipeline based on their key name using regex
// changing their name will brake the build pipeline

export const environment = {
  production: false,
  endpointsConfigEndpoint: 'https://config-api-z4ub5hxvha-ew.a.run.app/config/endpoints',
  auth0ClientId: '',
  auth0Domain: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
