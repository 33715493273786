<div fxLayout="column">
  <mat-toolbar fxFlex *ngIf="userLoggedIn" class="app-toolbar">
    <button mat-button (click)="showMenu = !showMenu">
      <i class="material-icons">menu</i>
    </button>
    <div class="logo">
    </div>
    <button mat-button *ngIf="isDevMode">
      <i class="material-icons">lock_open</i>
    </button>

    <span class="toolbar-spacer"></span>

    <span class="toolbar-spacer"></span>

  </mat-toolbar>

  <div class="content" fxFlex>
    <mat-drawer-container autosize hasBackdrop="false">
      <mat-drawer *ngIf="userLoggedIn" #drawer mode="side" [(opened)]="showMenu" class="side-nav">
        <div class="nav-menu-group">
          <button mat-button class="nav-menu-item">
            <i class="fas fa-chart-line icon-font-awesome-left-menu"></i>
            <span>Dashboard</span>
          </button>
        </div>

        <div class="nav-menu-group">
          <button (click)="showChat = !showChat" mat-button class="nav-menu-group-header">
            <i class="far fa-comments icon-font-awesome-left-menu"></i>
            Chat
            <i class="material-icons menu-fold" *ngIf="!showChat" fxFlexAlign="end">keyboard_arrow_down</i>
            <i class="material-icons menu-fold" *ngIf="showChat">keyboard_arrow_up</i>
          </button>
          <div *ngIf="showChat" class="nav-menu-sub-group">
            <div><button (click)="navigateToClientChats()" mat-button class="nav-menu-item">Client</button></div>
          </div>
          <div *ngIf="showChat" class="nav-menu-sub-group">
            <div><button (click)="navigateToClientChats()" mat-button class="nav-menu-item">Customer</button></div>
          </div>
        </div>

        <div class="nav-menu-group">
          <button (click)="showDisputes = !showDisputes" mat-button class="nav-menu-group-header">
            <i class="fas fa-gavel icon-font-awesome-left-menu"></i>
            Disputes
            <i class="material-icons menu-fold" *ngIf="!showDisputes" fxFlexAlign="end">keyboard_arrow_down</i>
            <i class="material-icons menu-fold" *ngIf="showDisputes">keyboard_arrow_up</i>
          </button>
          <div *ngIf="showDisputes" class="nav-menu-sub-group">
            <div><button (click)="navigateToTwitterDisputes()" mat-button class="nav-menu-item">Twitter</button></div>
          </div>
        </div>

      </mat-drawer>
      <mat-drawer-content class="drawer-content-at-least-vh">
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
