export interface Endpoints {
  floqy: Floqy;
  google: Google;
}

export interface Floqy {
  api: Api;
}

export interface Api {
  base: string[];
  flockit: Flockit;
  proxy: Proxy;
}

export interface Google {
  mapsPlaceUri: string;
}

export interface Chat {
  getClientsNeedingChatAttention: string;
  newMessage: string;
  getClientHistory: string;
  getClientArchived: string;
}

export interface Attention {
  getClientsNeedingAttention: string;
  resolveClientAttentionReason: string;
  setClientForChatAttention: string;
}

export interface Dispute {
  getUnresolvedMentionDisputes: string;
  getUnresolvedMentionDispute: string;
  getArchivedMentionDisputes: string;
  getArchivedMentionDispute: string;
  resolveDispute: string;
  getDisputeResolution: string;
  updateDispute: string;
}

export interface Flockit {
  getClient: string;
  getClientPlace: string;
  attention: Attention;
  chat: Chat;
  dispute: Dispute;
}

export interface Proxy {
  embedTweet: string;
}

export class AppSettings {
  endpoints: Endpoints = {
    floqy: {
      api: {
        base: [
          ''
        ],
        flockit: {
          getClient: '',
          getClientPlace: '',
          dispute: {
            getUnresolvedMentionDisputes: '',
            getUnresolvedMentionDispute: '',
            getArchivedMentionDisputes: '',
            getArchivedMentionDispute: '',
            resolveDispute: '',
            getDisputeResolution: '',
            updateDispute: ''
          },
          chat: {
            getClientsNeedingChatAttention: '',
            newMessage: '',
            getClientHistory: '',
            getClientArchived: ''
          },
          attention : {
            getClientsNeedingAttention: '',
            resolveClientAttentionReason: '',
            setClientForChatAttention: ''
          }
        },
        proxy: {
          embedTweet: ''
        }
      }
    },
    google: {
      mapsPlaceUri: ''
    }
  };
}
