export class ResolutionModel {

  public mentionId: string;
  public clearClientMention: boolean;
  public irrelevantMention: boolean;
  public multiplePlacesMentioned: boolean;
  public offensive: boolean;
  public chargeClient: boolean = null;
  public reviewUser: boolean = null;
  public clientVisibleMessage: string;
  public useDefaultBillableMessageToClient: boolean;
  public floqyPrivateMessage: string;
}
