import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {AppEventType} from '../events/app-event-type.enum';
import {AppEventsService} from '../events/app-event.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  public userLoggedIn: boolean;
  public showMenu = true;
  public showDisputes = false;
  public showChat = false;
  public isDevMode: boolean;

  constructor(private authService: AuthService,
              private appEvents: AppEventsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.userLoggedIn = this.authService.loggedIn;

    this.appEvents.on(AppEventType.UserLoggedOn).subscribe(() => {
      this.authService.isAuthenticated$.subscribe(() => {
        this.userLoggedIn = this.authService.loggedIn;
      });
    });

    this.appEvents.on(AppEventType.UserLoggedOff).subscribe(() => {
      this.userLoggedIn = false;
    });
  }

  navigateToTwitterDisputes() {
    this.router.navigate(['/disputes/twitter']);
  }

  navigateToClientChats() {
    this.router.navigate(['/chat/clients']);
  }
}
