import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../config/config.service';
import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {TwitterDisputesModel} from './models/twitter-disputes.model';

@Injectable({
  providedIn: 'root'
})
export class TwitterDisputesService{

  constructor(private httpClient: HttpClient,
              private configService: ConfigService) {

  }

  getUnresolvedDisputes(): Observable<Array<TwitterDisputesModel>> {
    return this.get(this.configService.getEndpoints().floqy.api.flockit.dispute.getUnresolvedMentionDisputes);
  }

  getArchivedDisputes(): Observable<Array<TwitterDisputesModel>> {
    return this.get(this.configService.getEndpoints().floqy.api.flockit.dispute.getArchivedMentionDisputes);
  }

  private get(endpoint: string): Observable<any> {
    return this.httpClient.get(endpoint)
      .pipe(retry(2));
  }
}
