import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ChatService} from './chat.service';
import {OutgoingChatMessage} from './models/outgoing-chat-message.model';
import {ChatMessage} from './models/chat-message.model';
import {interval, Subscription} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';
import {ClientModel} from './models/client.model';
import {ChatMessageDirection} from './enums/chat-message-direction.enum';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  public chatType: string;
  public clientId: string = null;
  public client: ClientModel;
  public history: Array<ChatMessage>;
  public archived: Array<ChatMessage> = null;
  public working = false;
  public outgoingMessage: string;
  public scrollBottom: number = null;
  public newMessagesInterval: Subscription;
  public scrollTimeout: any;
  public resolved = false;
  public clientChatType = 'client';
  public messageDirection = ChatMessageDirection;
  @ViewChild('scrollMe') chatHistory: ElementRef;

  constructor(private route: ActivatedRoute, private chatService: ChatService) { }

  ngOnInit(): void {
    this.chatType = this.route.snapshot.paramMap.get('chatType');

    if (this.chatType === this.clientChatType) {
      this.clientId = this.route.snapshot.paramMap.get('clientId');

      this.chatService.getClient(this.clientId).subscribe(client => {
        this.client = client;
      });

      this.pollClientHistory();
    }
  }

  pollClientHistory() {
    let firstCall = true;

    this.newMessagesInterval = interval(10000)
      .pipe(
        startWith(0),
        switchMap(() => this.chatService.getClientHistory(this.clientId))).subscribe(history => {
        this.history = history;

        if (this.history == null || this.history.length === 0) {
          this.history = new Array<ChatMessage>();
          this.resolved = true;

          return;
        }

        if (firstCall || this.history.some(x => x.unread === true)) {
          firstCall = false;

          this.scrollTimeout = setTimeout(() => {
            this.scrollBottom = this.chatHistory.nativeElement.scrollHeight;
          }, 100);
        }
      });
  }

  shortcutSend(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'Enter') {
      this.sendMessage();
    }
  }

  sendMessage() {
    if (!this.outgoingMessage || this.outgoingMessage === '') {
      return;
    }

    this.working = true;
    this.chatService.sendMessage(this.clientId, new OutgoingChatMessage(this.outgoingMessage)).then(() => {
      this.chatService.getClientHistory(this.clientId).subscribe(history => {
        this.history = history;
        this.working = false;
        this.outgoingMessage = '';
      });
    });
  }

  resolveChat() {
    if (this.chatType === this.clientChatType) {
      this.working = true;

      this.chatService.resolveClientChatAttention(this.clientId).then(() => {
        this.resolved = true;
        this.working = false;
      });
    }
  }

  undoResolution() {
    if (this.chatType === this.clientChatType) {
      this.working = true;

      this.chatService.setClientChatAttention(this.clientId).then(() => {
        this.working = false;
        this.resolved = false;
      });
    }
  }

  getArchivedMessages() {
    this.working = true;
    this.chatService.getClientArchived(this.clientId).subscribe(archived => {
      this.archived = archived;
      this.working = false;

      this.scrollTimeout = setTimeout(() => {
        this.scrollBottom = this.chatHistory.nativeElement.scrollHeight;
      }, 100);
    });
  }
}
