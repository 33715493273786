<meta name="twitter:widgets:theme" content="dark">
<meta name="twitter:dnt" content="on">
<meta name="twitter:widgets:csp" content="on">

<h1 mat-dialog-title class="mat-dialog-title">
  <span>
    <i>{{dispute.clientName}}</i> disputing <i>{{dispute.username}}</i>'s tweet
  </span>
  <mat-divider></mat-divider>
  <div class="loader-placeholder">
    <mat-progress-bar *ngIf="fetchingDispute" mode="indeterminate"></mat-progress-bar>
  </div>
</h1>

<div mat-dialog-content>
  <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 40px;">
    <div style="flex: 40%">
      <app-tweet [twitterUsername]="dispute.username" [tweetId]="dispute.id"></app-tweet>
    </div>

    <div style="flex: 60%; padding-top: 10px;">
      <div *ngIf="dispute.irrelevant || dispute.placeNotMentioned || dispute.differentPlaceMentioned">
        Reasons client wants to exclude this mention from their bill
        <mat-card class="client-input">
          <div *ngIf="dispute.differentPlaceMentioned">
            A different place is mentioned
          </div>
          <div *ngIf="dispute.placeNotMentioned">
            Client's place is not mentioned
          </div>
          <div *ngIf="dispute.irrelevant">
            Irrelevant tweet
          </div>
        </mat-card>
      </div>

      <div class="field-separation">
        <div *ngIf="!dispute.irrelevant && !dispute.placeNotMentioned && !dispute.differentPlaceMentioned">
          No additional comments from <i>{{dispute.clientName}}</i>
        </div>

        <div *ngIf="dispute.clientComments !== ''">
          Additional comments from <i>{{dispute.clientName}}</i>
        </div>
        <mat-card *ngIf="dispute.clientComments !== ''" class="client-input">
          {{dispute.clientComments}}
        </mat-card>

        <div *ngIf="dispute.clientComments === ''">
          Client did not provide any additional comments
        </div>
      </div>

      <div class="field-separation">
        <div>
          Place that should be mentioned
        </div>
        <mat-card class="client-input">
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div>
              {{place?.placeName}}
            </div>
            <div>
              <button mat-stroked-button (click)="openGoogleMaps()">View on Google Maps</button>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="field-separation">
        <mat-divider></mat-divider>
      </div>

      <div class="field-separation">
        <div class="field-header">
            About this tweet
        </div>
        <span style="color: darkgray; font-style: italic">Answer <strong>all questions</strong></span>
      </div>

      <div class="answer-separation">
        <mat-slide-toggle [(ngModel)]="resolution.clearClientMention"
                          name="clearClientMention"
                          (change)="setClearMention($event)">
          It clearly mentions either <i>{{dispute.clientName}}</i> or the above place
        </mat-slide-toggle>
      </div>
      <div class="answer-separation">
        <mat-slide-toggle color="warn"
                          [(ngModel)]="resolution.irrelevantMention"
                          name="irrelevantMention"
                          (change)="setIrrelevantMention($event)">
          Irrelevant tweet
        </mat-slide-toggle>
      </div>
      <div class="answer-separation">
        <mat-slide-toggle color="warn"
                          [(ngModel)]="resolution.multiplePlacesMentioned"
                          name="multiplePlacesMentioned"
                          (change)="setMultiplePlacesMentioned($event)">
          It mentions multiple places, not all owned by <i>{{dispute.clientName}}</i>
        </mat-slide-toggle>
      </div>
      <div class="answer-separation">
        <mat-slide-toggle color="warn" [(ngModel)]="resolution.offensive" name="offensive" (change)="setOffensive($event)">
          It's offensive (in any way, not just towards the client)
        </mat-slide-toggle>
      </div>

      <div class="field-separation">
        <div class="field-header">Should <i>{{dispute.clientName}}</i> pay for this mention?</div>
        <div>
          <mat-radio-group [(ngModel)]="resolution.chargeClient" name="chargeClient">
            <div>
              <mat-radio-button [value]="true"
                                [disabled]="resolution.offensive || resolution.irrelevantMention || resolution.multiplePlacesMentioned">
                Yes - mention follows our guidelines
              </mat-radio-button>
            </div>
            <div style="padding-top: 10px">
              <mat-radio-button [value]="false">
                No - mention is not valid
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>

      <div class="field-separation" *ngIf="resolution.offensive || resolution.multiplePlacesMentioned">
        <div class="field-header">Should we review <i>{{dispute.username}}</i>'s account for possible ban?</div>
        <div>
          <mat-radio-group [(ngModel)]="resolution.reviewUser">
            <div>
              <mat-radio-button [value]="true">Yes - looks intentional</mat-radio-button>
            </div>
            <div style="padding-top: 10px">
              <mat-radio-button [value]="false">No - probably a mistake</mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>

      <div class="field-separation" *ngIf="resolution.chargeClient === true">
        <h2>Let <i>{{dispute.clientName}}</i> know why we are charging them</h2>
        <div>
          <div>
            <mat-radio-group [(ngModel)]="resolution.useDefaultBillableMessageToClient">
              <div>
                <mat-radio-button [value]="true">Use default reply</mat-radio-button>
                <i class="fas fa-info fa-lg" style="padding-left: 20px;"
                   matTooltip="Default reply:&#13;&#13;&quot;Ask someone to to that, it's not done!&quot;"></i>
              </div>
              <div style="padding-top: 10px">
                <mat-radio-button [value]="false">I need to type a custom reply</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <mat-form-field *ngIf="resolution?.useDefaultBillableMessageToClient === false" style="width: 80%">
            <textarea matInput rows="4" [(ngModel)]="resolution.clientVisibleMessage" placeholder="Be polite and factual">
            </textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="field-separation" *ngIf="resolution.chargeClient === true">
        <h2>Any private notes to add? (visible to anyone in floqy)</h2>
        <div>
          <mat-form-field style="width: 80%">
            <textarea matInput rows="4" [(ngModel)]="resolution.floqyPrivateMessage"
                      placeholder="For floqy use only">
            </textarea>
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>
</div>

<mat-divider></mat-divider>

<div mat-dialog-actions>
  <div style="width:100%; display: flex; flex-direction: row; justify-content: space-between">
    <div>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>
    <div>
      <button *ngIf="data.state === disputeState.Unresolved.toString()"
              mat-raised-button color="primary" (click)="resolveDispute()"
              [disabled]="working || !(
              (resolution.chargeClient === false)
              || (resolution.chargeClient === true && resolution.useDefaultBillableMessageToClient === true)
              || (resolution.chargeClient === true && resolution.useDefaultBillableMessageToClient === false && resolution.clientVisibleMessage))">
        Resolve dispute
      </button>

      <button *ngIf="data.state === disputeState.Resolved.toString()"
              mat-raised-button color="primary" (click)="updateDispute()"
              [disabled]="working || !(
              (resolution.chargeClient === false)
              || (resolution.chargeClient === true && resolution.useDefaultBillableMessageToClient === true)
              || (resolution.chargeClient === true && resolution.useDefaultBillableMessageToClient === false && resolution.clientVisibleMessage))">
        Update
      </button>
    </div>
  </div>

</div>
