import {Component, Inject, OnInit} from '@angular/core';
import {TwitterDisputeModel} from './models/twitter-dispute.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TwitterDisputeService} from './twitter-dispute.service';
import {ResolutionModel} from './models/resolution.model';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Place} from './models/place.model';
import {ConfigService} from '../../config/config.service';
import {AppEventType} from '../../events/app-event-type.enum';
import {AppEvent} from '../../events/app-event';
import {AppEventsService} from '../../events/app-event.service';
import {Router} from '@angular/router';
import {DisputeStateEnum} from '../dispute-state.enum';

@Component({
  selector: 'app-twitter-dispute',
  templateUrl: './twitter-dispute.component.html',
  styleUrls: ['./twitter-dispute.component.scss']
})
export class TwitterDisputeComponent implements OnInit {

  public working = false;
  public fetchingDispute = true;
  public dispute: TwitterDisputeModel;
  public place: Place;
  public resolution: ResolutionModel = new ResolutionModel();
  public disputeState = DisputeStateEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<TwitterDisputeComponent>,
              private twitterDisputeService: TwitterDisputeService,
              private configService: ConfigService,
              private router: Router,
              private appEvents: AppEventsService) {

  }

  ngOnInit(): void {
    this.dispute = new TwitterDisputeModel(
      this.data.id,
      this.data.username,
      this.data.clientName,
      this.data.googlePlaceId,
      this.data.clientId);

    if (this.data.state === DisputeStateEnum.Unresolved.toString()) {
      this.loadUnresolvedDispute();
    }
    else {
      this.loadArchivedDispute();
      this.loadResolution();
    }

    this.loadAddress();
  }

  loadUnresolvedDispute() {
    this.twitterDisputeService.getUnresolvedDispute(this.dispute.id).then(dispute => {
      this.dispute = dispute;
      this.resolution.mentionId = dispute.id;

      this.fetchingDispute = false;
    });
  }

  loadArchivedDispute() {
    this.twitterDisputeService.getArchivedDispute(this.dispute.id).then(dispute => {
      this.dispute = dispute;
      this.resolution.mentionId = dispute.id;

      this.fetchingDispute = false;
    });
  }

  loadResolution() {
    this.twitterDisputeService.getDisputeResolution(this.dispute.id).then(resolution => {
      this.resolution = resolution;
    });
  }

  loadAddress() {
    this.twitterDisputeService.getPlaceAddress(this.data.googlePlaceId, this.data.clientId)
      .then(place => {
      this.place = place;

      this.place.mapsUrl = this.configService.getEndpoints().google.mapsPlaceUri
        .replace('{{googlePlaceId}}', this.data.googlePlaceId);
    });
  }

  setClearMention($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.resolution.irrelevantMention = false;
    }
  }

  setIrrelevantMention($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.resolution.clearClientMention = false;
      this.resolution.chargeClient = false;
    }
  }

  setMultiplePlacesMentioned($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.resolution.chargeClient = false;
    }
  }

  setOffensive($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.resolution.chargeClient = false;
    }
  }

  openGoogleMaps() {
    window.open(this.place.mapsUrl, '_blank');
  }

  resolveDispute() {
    this.working = true;

    this.twitterDisputeService.resolveDispute(this.resolution).then(() => {
      this.appEvents.broadcast(new AppEvent(AppEventType.TwitterDisputeResolutionAdded, TwitterDisputeModel, this.dispute));

      this.working = false;
      this.dialogRef.close();

      this.router.navigate(['/disputes/twitter']);
    });
  }

  updateDispute() {
    this.working = true;

    this.twitterDisputeService.updateDispute(this.resolution).then(() => {
      this.appEvents.broadcast(new AppEvent(AppEventType.TwitterDisputeResolutionAdded, TwitterDisputeModel, this.dispute));

      this.working = false;
      this.dialogRef.close();

      this.router.navigate(['/disputes/twitter']);
    });
  }
}
