import {ConfigService} from '../config/config.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {ChatMessage} from './models/chat-message.model';
import {OutgoingChatMessage} from './models/outgoing-chat-message.model';
import {ClientModel} from './models/client.model';
import {ResolveChatModel} from './models/resolve-chat.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private httpClient: HttpClient,
              private configService: ConfigService) {

  }

  getClient(clientId: string): Observable<ClientModel> {
    return this.get(this.configService.getEndpoints().floqy.api.flockit.getClient + clientId);
  }

  getClientHistory(clientId: string): Observable<Array<ChatMessage>> {
    return this.get(this.configService.getEndpoints().floqy.api.flockit.chat.getClientHistory + clientId);
  }

  getClientArchived(clientId: string): Observable<Array<ChatMessage>> {
    return this.get(this.configService.getEndpoints().floqy.api.flockit.chat.getClientArchived + clientId);
  }

  sendMessage(clientId: string, message: OutgoingChatMessage): Promise<any>{
    const endpoint = this.configService.getEndpoints().floqy.api.flockit.chat.newMessage + clientId;

    return this.post(endpoint, message).toPromise();
  }

  resolveClientChatAttention(clientId: string): Promise<any>{
    const endpoint = this.configService.getEndpoints().floqy.api.flockit.attention.resolveClientAttentionReason;
    const body = new ResolveChatModel(+clientId);

    return this.put(endpoint, body).toPromise();
  }

  setClientChatAttention(clientId: string): Promise<any>{
    const endpoint = this.configService.getEndpoints().floqy.api.flockit.attention.setClientForChatAttention
      .replace('{{clientId}}', clientId);

    return this.post(endpoint, null).toPromise();
  }

  private get(endpoint: string): Observable<any> {
    return this.httpClient.get(endpoint)
      .pipe(retry(2));
  }

  private post(endpoint: string, body: OutgoingChatMessage): Observable<any> {
    return this.httpClient.post<OutgoingChatMessage>(endpoint, body)
      .pipe(retry(2));
  }

  private put(endpoint: string, body: ResolveChatModel): Observable<any> {
    return this.httpClient.put<ResolveChatModel>(endpoint, body)
      .pipe(retry(2));
  }
}
