import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AppEvent} from './app-event';
import {AppEventType} from './app-event-type.enum';

@Injectable()
export class AppEventsService {

  private eventBroker = new Subject<AppEvent<any>>();

  on(eventType: AppEventType): Observable<AppEvent<any>> {
    return this.eventBroker.pipe(filter(event => event.name === eventType));
  }

  broadcast<T>(event: AppEvent<T>): void {
    this.eventBroker.next(event);
  }

}
