<div *ngIf="!loading && clients.length == 0"
     style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; height: 90vh">
  <div>
    <i class="far fa-comments fa-7x"></i>
  </div>
  <div style="font-size: 28px; padding-top: 30px;">
    No one in the mood for a chat today
  </div>
</div>

<table *ngIf="!loading && clients.length > 0" mat-table [dataSource]="clients" class="mat-elevation-z8" style="width: 100%">
  <tr mat-header-row *matHeaderRowDef="chatColumns" style="display: none;"></tr>
  <tr mat-row *matRowDef="let row; columns: chatColumns;" class="mat-row-hand" (click)="showChat(row)"></tr>

  <ng-container matColumnDef="clientName">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let client">
      {{client.clientName}}
    </td>
  </ng-container>

</table>

