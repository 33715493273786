export class TwitterDisputeModel {

  constructor(id: string, username: string, clientName: string, googlePlaceId: string, clientId: number) {
    this.id = id;
    this.username = username;
    this.clientName = clientName;
    this.googlePlaceId = googlePlaceId;
    this.clientId = clientId;
  }

  public id: string;
  public username: string;
  public placeNotMentioned: boolean;
  public differentPlaceMentioned: boolean;
  public irrelevant: boolean;
  public clientComments: string;
  public floqyComments: string;
  public resolutionStatus: string;
  public clientName: string;
  public googlePlaceId: string;
  public clientId: number;
}
