import { Component, OnInit } from '@angular/core';
import {TwitterDisputesService} from './twitter-disputes.service';
import {TwitterDisputesModel} from './models/twitter-disputes.model';
import {MatDialog} from '@angular/material/dialog';
import {TwitterDisputeComponent} from '../twitter-dispute/twitter-dispute.component';
import {AppEventsService} from '../../events/app-event.service';
import {AppEventType} from '../../events/app-event-type.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TwitterDisputeModel} from '../twitter-dispute/models/twitter-dispute.model';
import {DisputeStateEnum} from '../dispute-state.enum';

@Component({
  selector: 'app-twitter-disputes',
  templateUrl: './twitter-disputes.component.html',
  styleUrls: ['./twitter-disputes.component.scss']
})
export class TwitterDisputesComponent implements OnInit {

  public loading = true;
  public displayUnresolvedDisputes = true;
  public displayArchivedDisputes = false;
  public disputesColumns: string[] = ['tweetId', 'user', 'client', 'placeNotMentioned', 'differentPlaceMentioned', 'irrelevant'];
  public unresolvedDisputes: Array<TwitterDisputesModel>;
  public archivedDisputes: Array<TwitterDisputesModel>;
  public disputeState = DisputeStateEnum;

  constructor(private twitterDisputesService: TwitterDisputesService,
              private appEventsService: AppEventsService,
              private disputeDialog: MatDialog,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.appEventsService.on(AppEventType.TwitterDisputeResolutionAdded)
      .subscribe(resolution => {
        this.handleNewResolution(resolution.payload);
      });

    this.loadUnresolvedDisputes();
  }

  handleNewResolution(dispute: TwitterDisputeModel) {
    this.loadUnresolvedDisputes();

    this.snackBar.open(dispute.clientName + '\'s dispute resolved', 'OK', {
      duration: 3000
    });
  }

  loadUnresolvedDisputes() {
    this.twitterDisputesService.getUnresolvedDisputes().subscribe(disputes => {
      this.unresolvedDisputes = disputes;

      this.loading = false;
    });
  }

  loadArchivedDisputes() {
    this.twitterDisputesService.getArchivedDisputes().subscribe(disputes => {
      this.archivedDisputes = disputes;

      this.loading = false;
    });
  }

  showUnresolvedDisputes() {
    this.displayUnresolvedDisputes = true;
    this.displayArchivedDisputes = false;
  }

  showArchivedDisputes() {
    this.displayArchivedDisputes = true;
    this.displayUnresolvedDisputes = false;

    this.loadArchivedDisputes();
  }

  showDispute(row: any, state: DisputeStateEnum) {
    this.disputeDialog.open(TwitterDisputeComponent, {
      width: '70vw',
      autoFocus: false,
      data: {
        id: row.id,
        username: row.username,
        clientName: row.clientName,
        googlePlaceId: row.googlePlaceId,
        clientId: row.clientId,
        state: state.toString()
      }
    });
  }
}
