import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../config/config.service';
import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {TwitterDisputeModel} from './models/twitter-dispute.model';
import {Place} from './models/place.model';
import {ResolutionModel} from './models/resolution.model';

@Injectable({
  providedIn: 'root'
})
export class TwitterDisputeService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getUnresolvedDispute(disputeId: string): Promise<TwitterDisputeModel> {
    const endpoint = this.configService.getEndpoints().floqy.api.flockit.dispute.getUnresolvedMentionDispute + disputeId;

    return this.get(endpoint).toPromise();
  }

  getArchivedDispute(disputeId: string): Promise<TwitterDisputeModel> {
    const endpoint = this.configService.getEndpoints().floqy.api.flockit.dispute.getArchivedMentionDispute + disputeId;

    return this.get(endpoint).toPromise();
  }

  getPlaceAddress(googlePlaceId: string, clientId: number): Promise<Place> {
    const endpoint = this.configService.getEndpoints().floqy.api.flockit.getClientPlace
        .replace('{{clientId}}', clientId.toString())
        .replace('{{googlePlaceId}}', googlePlaceId);

    return this.get(endpoint).toPromise();
  }

  resolveDispute(resolution: ResolutionModel): Promise<any>{
    return this.post(this.configService.getEndpoints().floqy.api.flockit.dispute.resolveDispute, resolution)
      .toPromise();
  }

  getDisputeResolution(disputeId: string): Promise<ResolutionModel> {
    return this.get(this.configService.getEndpoints().floqy.api.flockit.dispute.getDisputeResolution + disputeId)
      .toPromise();
  }

  updateDispute(resolution: ResolutionModel): Promise<any>{
    return this.put(this.configService.getEndpoints().floqy.api.flockit.dispute.updateDispute, resolution)
      .toPromise();
  }

  private get(endpoint: string): Observable<any> {
    return this.httpClient.get(endpoint)
      .pipe(retry(2));
  }

  private post(endpoint: string, body: ResolutionModel): Observable<any> {
    return this.httpClient.post<ResolutionModel>(endpoint, body)
      .pipe(retry(2));
  }

  private put(endpoint: string, body: ResolutionModel): Observable<any> {
    return this.httpClient.put<ResolutionModel>(endpoint, body)
      .pipe(retry(2));
  }
}
