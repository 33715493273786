<div class="welcome-page-container">
  <div fxLayout="column" fxLayoutAlign="center center" class="content-container">
    <div fxFlex="100%" style="text-align: center; padding-top: 30px;">
      <h1 style="font-size: 3em" mat-dialog-title class="mat-dialog-title">Flockit!</h1>

      <h1 style="width: 90vw">
        <mat-divider></mat-divider>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </h1>
    </div>

    <div fxFlex="100%" style="text-align: center; margin-top: 30vh">
      <button mat-raised-button (click)="login()" [disabled]="working" color="primary" style="min-width: 100px">
        Login using Google account
      </button>
    </div>
  </div>
</div>
