import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ConfigComponent } from './config/config.component';
import { RootComponent } from './root/root.component';
import { AuthComponent } from './auth/auth.component';
import { EventsComponent } from './events/events.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from './login/login.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {AppEventsService} from './events/app-event.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import {ConfigService} from './config/config.service';
import {ConfigServiceProvider} from './config/config.service-provider';
import { TwitterDisputesComponent } from './disputes/twitter-disputes/twitter-disputes.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTableModule} from '@angular/material/table';
import { TwitterDisputeComponent } from './disputes/twitter-dispute/twitter-dispute.component';
import { TweetComponent } from './tweet/tweet.component';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ClientChatComponent } from './client-chat/client-chat.component';
import {TokenInterceptor} from './auth/token.interceptor';
import { ChatComponent } from './chat/chat.component';

@NgModule({
  declarations: [
    ConfigComponent,
    RootComponent,
    AuthComponent,
    EventsComponent,
    LoginComponent,
    DashboardComponent,
    TwitterDisputesComponent,
    TwitterDisputeComponent,
    TweetComponent,
    ClientChatComponent,
    ChatComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatDividerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatTableModule,
    MatListModule,
    MatCardModule,
    MatSlideToggleModule,
    FormsModule,
    MatRadioModule,
    MatInputModule,
    MatTooltipModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: APP_INITIALIZER,
      useFactory: ConfigServiceProvider.configServiceProvider,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ConfigService,
    AppEventsService
  ],
  bootstrap: [RootComponent]
})
export class AppModule { }
