import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AppSettings, Endpoints} from './models/config-endpoints.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private httpClient: HttpClient;
  private appSettings: AppSettings;

  constructor(private httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
  }

  load(): Promise<boolean> {
    this.appSettings = new AppSettings();

    return new Promise((resolve) => {
      this.getSettings(environment.endpointsConfigEndpoint).then(endpoints => {
        this.appSettings = endpoints;

        resolve(true);
      });
    });
  }

  getEndpoints(): Endpoints {
    return this.appSettings.endpoints;
  }

  private getSettings(endpoint: string): Promise<AppSettings> {
    return new Promise<any>((resolve) => {
      this.httpClient.get(endpoint).subscribe(endpoints => {
        resolve(endpoints);
      });
    });
  }
}
