import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {AuthService} from './auth.service';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.auth.getToken())
      .pipe(
        switchMap(token => {

          if (token == null || token === '') {
            return next.handle(request);
          }

          const headers = request.headers.set('Authorization', 'Bearer ' + token);
          const requestClone = request.clone({
            headers
          });

          return next.handle(requestClone);
        })
      );
  }
}
