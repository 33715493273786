<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="1%" style="padding-left: 5px">
  <div fxFlex="60">
    <div *ngIf="chatType === clientChatType" style="text-align: center">
      <h1>{{client?.clientName}}</h1>
    </div>
    <div *ngIf="!resolved">
      <button mat-stroked-button (click)="resolveChat()" [disabled]="working" matTooltip="Send message">
        Mark as resolved
      </button>
    </div>
    <div *ngIf="resolved">
      <button mat-stroked-button (click)="undoResolution()" [disabled]="working" matTooltip="Send message">
        Mark as open chat
      </button>
    </div>
  </div>

  <div fxFlex="40" style="border-left: 1px #666666 solid; padding-left: 5px;">
    <div fxLayout="column" fxLayoutAlign="end stretch" class="drawer-content-exactly-vh">
      <div fxFlex style="padding: 10px 10px 0 0; overflow-y: auto;" #scrollMe [scrollTop]="scrollBottom">

        <div *ngIf="history?.length === 0 && archived == null" class="splash-screen-container">
          <div>
            <i class="far fa-comments fa-7x"></i>
          </div>
          <div class="splash-screen">
            No unresolved chat messages for {{client?.clientName}}
          </div>
          <div>
            <button mat-raised-button (click)="getArchivedMessages()" [disabled]="working">
              Load archive
            </button>
          </div>
        </div>

        <div *ngIf="archived?.length > 0" fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="4%"
             style="width: 50vw; margin: auto; padding-bottom: 20px;">
          <div fxFlex *ngFor="let message of archived" style="position: relative;" class="{{message.unread ? 'new-message' : ''}}">
            <mat-card class="{{message.messageDirection === messageDirection.Outbound ? 'outbound' : 'inbound'}}"
                      style="padding-bottom: 3px; padding-top: 10px;">
              <div class="content">
                {{message.content}}
              </div>
              <div class="message-footer">
                <div fxLayout="row" fxLayoutAlign="space-between end">
                  <div fxFlex>
                    {{message.messageDirection === messageDirection.Outbound? message.from : client?.clientName}}
                  </div>
                  <div fxFlex="30px">

                  </div>

                  <div fxFlex style="text-align: right">
                    {{message.timestamp | date: 'dd MMM HH:mm:ss'}}
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>

        <div *ngIf="history?.length > 0" fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="4%" style="width: 50vw; margin: auto;">
          <div fxFlex *ngFor="let message of history" style="position: relative;" class="{{message.unread ? 'new-message' : ''}}">
            <mat-card class="{{message.messageDirection === messageDirection.Outbound ? 'outbound' : 'inbound'}}"
                      style="padding-bottom: 3px; padding-top: 10px;">
              <div class="content">
                {{message.content}}
              </div>
              <div class="message-footer">
                <div fxLayout="row" fxLayoutAlign="space-between end">
                  <div fxFlex>
                    {{message.messageDirection === messageDirection.Outbound ? message.from : client?.clientName}}
                  </div>
                  <div fxFlex="30px">

                  </div>

                  <div fxFlex style="text-align: right">
                    {{message.timestamp | date: 'dd MMM HH:mm:ss'}}
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div fxFlex="130px" fxFill>
        <div style="padding: 10px 0 10px 0">
          <mat-divider></mat-divider>
        </div>

        <div style="width: 90%; margin: auto">
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1%">

            <div fxFlex="80">
              <mat-form-field class="mat-form-field">
            <textarea matInput rows="3" placeholder="New message" name="outgoingMessage"
                      [(ngModel)]="outgoingMessage" (keydown)="shortcutSend($event)" [disabled]="working"></textarea>
              </mat-form-field>
            </div>

            <div fxFlex="20">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
                <div fxFlex>
                  <button mat-stroked-button (click)="sendMessage()" [disabled]="working" class="tt-multiline"
                          matTooltip="Enter for new line&#13; Ctrl + enter to send">
                    SEND
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
