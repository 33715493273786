import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {EmbeddedTweet} from './models/embedded-tweet.model';
import {ConfigService} from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class TweetService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getEmbedded(username: string, tweetId: string): Promise<EmbeddedTweet> {
    const endpoint = this.configService.getEndpoints().floqy.api.proxy.embedTweet
      .replace('{{twitterUsername}}', username)
      .replace('{{tweetId}}', tweetId);

    return this.get(endpoint).toPromise();
  }

  private get(endpoint: string): Observable<any> {
    return this.httpClient.get(endpoint)
      .pipe(retry(2));
  }

}
