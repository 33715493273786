import { Component, OnInit } from '@angular/core';
import {ClientChatService} from './client-chat.service';
import {ClientModel} from './models/client.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-client-chat',
  templateUrl: './client-chat.component.html',
  styleUrls: ['./client-chat.component.scss']
})
export class ClientChatComponent implements OnInit {

  public loading = true;
  public chatColumns: string[] = ['clientName'];
  public clients: Array<ClientModel>;

  constructor(private clientChatService: ClientChatService,
              private router: Router) { }

  ngOnInit(): void {
    this.clients = new Array<ClientModel>();

    this.loadClients();
  }

  private loadClients() {
    this.clientChatService.getClientsNeedingChat().subscribe(clients => {
      this.clients = clients;
      this.loading = false;
    });
  }

  showChat(row: any) {
    const url = '/chat/client/' + row.id;
    this.router.navigate([url]);
  }
}
