<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div style="display:flex; align-items: center; justify-content: center; width: 100%; padding-top: 20px;">
  <div>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
      <mat-button-toggle [checked]="displayUnresolvedDisputes" (change)="showUnresolvedDisputes()" class="status-button">
        Unresolved
      </mat-button-toggle>
      <mat-button-toggle [checked]="displayArchivedDisputes" (change)="showArchivedDisputes()"class="status-button">
        Archived
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<div *ngIf="displayUnresolvedDisputes">
  <div *ngIf="unresolvedDisputes?.length === 0" style="padding: 5vh 0 10vh 0; width:100%; text-align: center;">
    <div style="padding-bottom: 20px;">
      <i class="fas fa-umbrella-beach fa-6x"></i>
    </div>
    <div class="big-text">
      <div>
        No unresolved disputes
      </div>
    </div>
  </div>

  <div *ngIf="unresolvedDisputes?.length != 0" style="padding-top: 20px;">
    <table *ngIf="!loading" mat-table [dataSource]="unresolvedDisputes" class="mat-elevation-z8" style="width: 100%">
      <tr mat-header-row *matHeaderRowDef="disputesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: disputesColumns;" class="mat-row-hand" (click)="showDispute(row, disputeState.Unresolved)"></tr>

      <ng-container matColumnDef="tweetId">
        <th mat-header-cell *matHeaderCellDef> Tweet ID </th>
        <td mat-cell *matCellDef="let dispute">
          {{dispute.id}}
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> Twitter user </th>
        <td mat-cell *matCellDef="let dispute">
          {{dispute.username}}
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef> Client </th>
        <td mat-cell *matCellDef="let dispute">
          {{dispute.clientName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="placeNotMentioned">
        <th mat-header-cell *matHeaderCellDef style="text-align: center"> Place not mentioned </th>
        <td mat-cell *matCellDef="let dispute" style="text-align: center">
          <span class="material-icons"
                *ngIf="dispute.placeNotMentioned">
            check_circle
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="differentPlaceMentioned">
        <th mat-header-cell *matHeaderCellDef style="text-align: center"> Different place mentioned </th>
        <td mat-cell *matCellDef="let dispute" style="text-align: center">
          <span class="material-icons"
                *ngIf="dispute.differentPlaceMentioned">
            check_circle
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="irrelevant">
        <th mat-header-cell *matHeaderCellDef style="text-align: center"> Irrelevant </th>
        <td mat-cell *matCellDef="let dispute" style="text-align: center">
          <span class="material-icons"
                *ngIf="dispute.irrelevant">
            check_circle
          </span>
        </td>
      </ng-container>

    </table>
  </div>
</div>

<div *ngIf="displayArchivedDisputes" style="padding-top: 20px;">
  <table *ngIf="!loading" mat-table [dataSource]="archivedDisputes" class="mat-elevation-z8" style="width: 100%">
    <tr mat-header-row *matHeaderRowDef="disputesColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: disputesColumns;" class="mat-row-hand" (click)="showDispute(row, disputeState.Resolved)"></tr>

    <ng-container matColumnDef="tweetId">
      <th mat-header-cell *matHeaderCellDef> Tweet ID </th>
      <td mat-cell *matCellDef="let dispute">
        {{dispute.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef> Twitter user </th>
      <td mat-cell *matCellDef="let dispute">
        {{dispute.username}}
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef> Client </th>
      <td mat-cell *matCellDef="let dispute">
        {{dispute.clientName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="placeNotMentioned">
      <th mat-header-cell *matHeaderCellDef style="text-align: center"> Place not mentioned </th>
      <td mat-cell *matCellDef="let dispute" style="text-align: center">
          <span class="material-icons"
                *ngIf="dispute.placeNotMentioned">
            check_circle
          </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="differentPlaceMentioned">
      <th mat-header-cell *matHeaderCellDef style="text-align: center"> Different place mentioned </th>
      <td mat-cell *matCellDef="let dispute" style="text-align: center">
          <span class="material-icons"
                *ngIf="dispute.differentPlaceMentioned">
            check_circle
          </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="irrelevant">
      <th mat-header-cell *matHeaderCellDef style="text-align: center"> Irrelevant </th>
      <td mat-cell *matCellDef="let dispute" style="text-align: center">
          <span class="material-icons"
                *ngIf="dispute.irrelevant">
            check_circle
          </span>
      </td>
    </ng-container>

  </table>
</div>
