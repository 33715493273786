import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../config/config.service';
import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {ClientModel} from './models/client.model';

@Injectable({
  providedIn: 'root'
})
export class ClientChatService {

  constructor(private httpClient: HttpClient,
              private configService: ConfigService) {

  }

  public getClientsNeedingChat(): Observable<Array<ClientModel>> {
    const endpoint = this.configService.getEndpoints().floqy.api.flockit.chat.getClientsNeedingChatAttention;

    return this.get(endpoint);
  }

  private get(endpoint: string): Observable<any> {
    return this.httpClient.get(endpoint)
      .pipe(retry(2));
  }
}
